<template>

    <div class="level_index level_indexI">

        <div class="search">

            <!-- <el-form :inline="true">
                <el-form-item>
                    <el-button v-if="expandKeys" type="primary" @click="toggleRowExpansion(true)">
                        全部展开
                    </el-button>
                    <el-button v-else type="danger" @click="toggleRowExpansion(false)">
                        全部折叠
                    </el-button>
                </el-form-item>
            </el-form> -->

        </div>

        <el-table class="el-tableII FanfuTableBox" :data="data.list" stripe height="100%" border ref="multipleTable"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="id" lazy :load="load">

            <el-table-column show-overflow-tooltip type="selection" width="40" align="center" fixed="left" />
            <el-table-column show-overflow-tooltip label="ID" type="index" align="center" fixed="left" />

            <el-table-column show-overflow-tooltip prop="name" label="城市名称" align="left" />

            <el-table-column show-overflow-tooltip prop="" label="城市级别" align="center" width="72">
                <template slot-scope="scope">
                    <el-button type="primary" round v-if="scope.row.level==1" size="mini">省份</el-button>
                    <el-button type="danger" round v-if="scope.row.level==2" size="mini">城市</el-button>
                    <el-button type="warning" round v-if="scope.row.level==3" size="mini">区县</el-button>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="citycode" label="城市编码（区号）" align="center" />

            <el-table-column show-overflow-tooltip prop="sort" label="排序" align="center" min-width="70" />

        </el-table>

    </div>
</template>

<script>
    import resizeDetector from 'element-resize-detector'

    export default {

        data() {
            return {

                loading: false,

                select: [],

                data: {
                    list: [],
                    page: 1,
                    size: 15,
                    total: 0
                },

                // searchtitle: "",

                expandKeys: true
            }
        },

        mounted() {
            this.modifyLayout();
        },

        created() {
            this.modifyLayout();

            this.getList();
        },
        methods: {

            toggleRowExpansion(val) {
                this.expandKeys = !this.expandKeys
                this.formatTree(this.data.list, 0)
            },

            formatTree(tree, val, val1) {
                for (const node of tree) {

                    if (this.expandKeys) {
                        this.$refs.multipleTable.toggleRowExpansion(node, false);
                    } else {
                        this.$refs.multipleTable.toggleRowExpansion(node, true);
                    }

                    if (node.children && node.children.length) {
                        this.formatTree(node.children, val + 1)
                    }
                }
            },

            expandChange(row, expandRow) {
                console.log(row, expandRow);
            },



            getList() {

                this.$http.get('/api/city/getChilds', {
                    pid: 0

                }).then((res) => {

                    if (res.code == 200) {

                        this.data.list = res.data.map(x => {
                            x.hasChildren = true
                            return x
                        })

                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },

            load(tree, treeNode, resolve) {

                this.$http.get('/api/city/getChilds', {
                    pid: tree.id
                }).then((res) => {

                    if (res.code == 200) {

                        resolve(res.data.map(x => {
                            if (x.level == 3) {
                                x.hasChildren = false
                            } else {
                                x.hasChildren = true
                            }
                            return x
                        }))

                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });

            },







            view(row) {
                console.log(row)
            },

            edit(row) {
                console.log(row)

                // this.$refs.searchRef.edit(row)

            },





            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';

                            // setTimeout(() => {
                            //     done();
                            //     setTimeout(() => {
                            //         instance.confirmButtonLoading = false;
                            //     }, 300);
                            // }, 3000);

                            this.$http.del('/api/menu/' + row.id).then((res) => {
                                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                                if (res.code == 200) {
                                    this.getList()
                                }
                                if (res.code != 200) { //请求错误
                                    this.$message.error(res.msg);
                                }

                                // setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                                // }, 3000);


                            }).catch((err) => {
                                console.log(err)
                            });


                        } else {
                            done();
                        }
                    }

                }).then(action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'action: ' + action
                    // });
                });
            },


            toSearch() {
                // this.$refs.searchRef.open()
            },
            toSearchChild(row) {
                // this.$refs.searchRef.openChild(row)
            },


            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;


                    var a = 141 + 60 - 35 + height - 60 - 20;
                    if ($(".level_indexI .FanfuTableBox")[0]) {

                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');

                    }


                    var b = 141 + 60 + 33 + height - 60 - 20;
                    if ($(".level_indexI .el-table")[0]) {

                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');

                    }


                });

            },




        },


    }
</script>





<style lang="scss" scoped>
    .el-button--success {
        color: #ffffff;
        background-color: #5BD995;
        border-color: #5BD995;
    }
</style>


<style scoped>
    /*  .level_indexI .FanfuTableBox {
    border: 1px blue dotted;
    box-sizing: border-box;
  } */
    /*  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini { */

    /*  .level_indexI .el-table {
    border: 1px red dotted;
    box-sizing: border-box;
  } */
</style>